import {HeroSection, PlayHeroSection} from "../components";
import DigitalAwardSection from "../components/DigitalAwardSection/DigitalAwardSection";
import DiscoverPrizeSection from "../components/DiscoverPrizeSection/DiscoverPrizeSection";
import Subscribe from "../components/Subscribe/Subscribe";
import Summary from "../components/Summary/Summary";
import ClaimPrize from "../components/ClaimPrize/ClaimPrize";
import ModalSubscribe from "../components/ModalSubscribe/ModalSubscribe";
import useModalUtils from "../utils/useModalUtils";
import "../assets/scss/page/Home.scss"
import {useUser} from "../utils/useUserExist";
import {useAuthContext} from "../utils/AuthContext";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Modal from "../components/Modal/Modal";
import CannotParticipate from "../components/CannotParticipate/CannotParticipate";
import TransactionsSuspended from "../components/TransactionsSuspended/TransactionsSuspended";
import { environment } from "../environment/environment";
import { debug } from "console";
import ModalError from "../components/ModalError/ModalError";
import locale from "../assets/locale.json";
import { track } from "../utils/Tracking";

export const Home = () => {
    const navigate = useNavigate();
    const {openModal, closeModal, isModalOpen, ModalType} = useModalUtils();
    const [isModalAlreadyOpened, setIsModalAlreadyOpened] = useState(false);

    const user = useUser();
    const {isAuth} = useAuthContext();
    const {responseError} = useAuthContext();

    const openSubscribeModal = () => {
        track({
        touchpoint: 'WEB',
        alias_page: 'alias_payandwin_modale_chiusura',
        section: 'Iscrizione',
        title_page: 'Modale Chiusura Iscrizione',
        site_section: 'Pay and Win'
        });

        return openModal(ModalType.SUBSCRIBE);
    }

    const [searchParams] = useSearchParams();

    const openModalParam = searchParams.get("openModal");
    const isOpenModal = openModalParam === "true";

    useEffect(() => {
        if (!isModalAlreadyOpened) {
            if (!(isAuth || user)) {
                if(isOpenModal) {
                    openSubscribeModal();
                    setIsModalAlreadyOpened(true);
                }
            } else if (responseError === 403) {
                openModal(ModalType.CANNOT_PARTICIPATE);
                setIsModalAlreadyOpened(true);
            } 
        }
    }, [isAuth, user, responseError, isModalAlreadyOpened, openModal, ModalType]);

    const onModalClose = () => {
        navigate("/")
        closeModal(ModalType.CLOSE)
    }

    return (
        <div className="pw-container-home-page">
            {isAuth || user ? (
                <>
                    <PlayHeroSection/>
                    <iframe className="prize-iframe-dashboard"
                            src="https://serving-eu.global/ap/M4XgnAHBCMAs0CYDcBjE0DMsZgAxIBd0sYIwg/postepaypromopayandwinpremialit%C3%A0"
                            style={{width: '100vw', border: 'none'}}></iframe>
                    <DigitalAwardSection/>
                    <ClaimPrize/>
                </>
            ) : (
                <>
                    <iframe id="subscribe-frame" className="hero-iframe"
                            src="https://serving-eu.global/ap/M4XgnAHBCMAs0CYDcBjE0DMsYHYAMSALuljAGxhA/postepaypromopayandwin"
                            style={{width: '100vw', border: 'none'}}></iframe>
                    <Summary/>
                    <iframe className="iscriviti-iframe"
                            src="https://serving-eu.global/ap/M4XgnAHBCMAs0CYDcBjE0DMsZmQF3Sx2iA/postepaypromopayandwinbanner"
                            style={{width: '100vw', border: 'none'}}></iframe>

                    <iframe className="prize-iframe"
                            src="https://serving-eu.global/ap/M4XgnAHBCMAs0CYDcBjE0DMsZgAxIBd0sYIwg/postepaypromopayandwinpremialit%C3%A0"
                            style={{width: '100vw', border: 'none'}}></iframe>
                    <DigitalAwardSection/>
                    <iframe className="iscriviti-iframe"
                            src="https://serving-eu.global/ap/M4XgnAHBCMAs0CYDcBjE0DMsZmQF3Sx2iA/postepaypromopayandwinbanner"
                            style={{width: '100vw', border: 'none'}}></iframe>

                </>
            )}

            <>
                <ModalSubscribe isOpen={isModalOpen(ModalType.SUBSCRIBE)} onClose={() => onModalClose()}/>
                <Modal
                    isOpen={isModalOpen(ModalType.CANNOT_PARTICIPATE)}
                    onClose={() => onModalClose()}
                    isCannotPartecipateContent
                >
                    <CannotParticipate onClose={() => onModalClose()}/>
                </Modal>
                <Modal
                    isOpen={isModalOpen(ModalType.TRANSACTIONS_SUSPENDED)}
                    onClose={() => onModalClose()}
                    isCannotPartecipateContent
                >   
                    <TransactionsSuspended onClose={() => onModalClose()}/>
                </Modal>
                <ModalError
                    title={locale.modal_error.errorRegistrationEmail.title}
                    description={locale.modal_error.errorRegistrationEmail.description}
                    button={{
                    label: locale.modal_error.errorRegistrationEmail.button.label,
                    theme: "blue",
                    onClick: () => closeModal(ModalType.CLOSE)
                    }}
                    isOpen={isModalOpen(ModalType.MODAL_ERROR_EMAIL)}
                />
            </>
        </div>
    );
};
