import "./TransactionsSuspended.scss";
import SimpleContent from "../SimpleContent/SimpleContent";
import locale from "../../assets/locale.json";
import imgCannotPartecipate from "../../assets/images/cannot-partecipate.webp";
import imgFinishedPlays from "../../assets/images/finished_plays.webp";
import HighlightedParagraph from "../HighlightedParagraph/HighlightedParagraph";
import { useNavigate } from "react-router-dom";
import { ContentProps } from "../../models/content";

const TransactionsSuspended: React.FC<ContentProps> = ({onClose = () => {}}) => {
  const backToHome = useNavigate();

  const closeForever = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    
    localStorage.setItem("closeForever", JSON.stringify({
      value: true,
      expiry: tomorrow.getTime()
    }));
    onClose();
  } 

  return (
    <div className="pw-cannot-partecipate-container">
      <SimpleContent
        image={{
          alt: locale.cannot_partecipate.alt_image,
          src: imgFinishedPlays,
        }}
        title={locale.transactions_suspended.title}
        subtitle={{ __html: locale.transactions_suspended.subtitle }}
        confirmationButton={{
          theme: "blue",
          label: locale.cannot_partecipate.label_button,
          onClick: () => {
            onClose();
            closeForever();
            backToHome(locale.cannot_partecipate.button_url)
          },
        }}
      />
    </div>
  );
};

export default TransactionsSuspended;